@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    /* background: rgb(66, 56, 251);
    background: linear-gradient(45deg, rgba(66, 56, 251, 1) 0%, rgba(177, 0, 163, 1) 100%); */
}

.sections {
    @apply mb-4 flex flex-wrap;
}

button {
    @apply border rounded bg-gray-200 border-gray-500 text-gray-500 text-xs m-0.5 p-1 cursor-pointer flex-grow;
}

button.active {
    @apply bg-gray-600 text-white;
}

.btn {
    @apply rounded p-1 h-auto min-h-0 text-sm normal-case;
}


.btn-accent{
    @apply bg-gradient-to-r from-pink-500 to-pink-700 border-pink-800 text-white;
}

.btn-secondary{
    @apply bg-indigo-500 border-indigo-800 text-white;
    
}